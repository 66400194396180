import React from "react";
import me01 from "../images/me01.jpg";

class sidebar extends React.Component {
  toggle = e => {
    this.props.toggle(e.target.textContent.toLowerCase());
  };
  render() {
    return (
      <div id="sidebar" className="four wide column">
        <img
          className="ui small circular centered image"
          src={me01}
          alt="profile avatar"
        ></img>
        <h1 className="ui icon header">
          <div className="content">
            Jeremiah Graham
            <div className="sub header">Full Stack Web Developer</div>
          </div>
        </h1>
        <div className="ui secondary vertical menu">
          <div className="item" onClick={this.toggle}>
            ABOUT ME
          </div>
          <div className="item" onClick={this.toggle}>
            PORTFOLIO
          </div>
          <div className="item" onClick={this.toggle}>
            CONTACT
          </div>
        </div>
        <div id="social-icons" className="ui icon basic buttons">
          <a href="https://www.linkedin.com/in/jtgraham5th/">
            <button className="ui button">
              <i className="large linkedin icon"></i>
            </button>
          </a>
          <a href="https://github.com/jtgraham5th">
            <button className="ui button">
              <i className="large github icon"></i>
            </button>
          </a>
          <a href="mailto:jtgraham5th@gmail.com">
            <button className="ui button">
              <i className="large mail icon"></i>
            </button>
          </a>
        </div>
      </div>
    );
  }
}
export default sidebar;
