import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";

class Mobile extends React.Component {
  state = { toggle: false };

  toggle = e => {
    this.props.toggle(e.target.textContent.toLowerCase());
  };

  render() {
    return (
      <>
        <div
          className="ui attached row"
          style={{ paddingBottom: "0px" }}
          id="mobile-nav"
        >
          <div className="ui custom-green horizontal segments column">
            <Menu>
              <Menu.Item href="https://www.linkedin.com/in/jeremiah-graham-5th/">
                <i className="large linkedin icon"></i>
              </Menu.Item>
              <Menu.Item href="https://github.com/jtgraham5th">
                <i className="large github icon"></i>
              </Menu.Item>
              <Menu.Item href="mailto:jtgraham5th@gmail.com">
                <i className="large mail icon"></i>
              </Menu.Item>
              <Menu.Menu position="right">
                <Dropdown button className="large icon" icon="bars">
                  <Dropdown.Menu id="dropdown-menu">
                    <Dropdown.Item className="" onClick={this.toggle}>
                      ABOUT ME
                    </Dropdown.Item>
                    <Dropdown.Item className="" onClick={this.toggle}>
                      PORTFOLIO
                    </Dropdown.Item>
                    <Dropdown.Item className="" onClick={this.toggle}>
                      RESUME
                    </Dropdown.Item>
                    <Dropdown.Item className="" onClick={this.toggle}>
                      CONTACT
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </div>
        </div>
      </>
    );
  }
}
export default Mobile;
