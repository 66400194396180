import React from "react";

class Profile extends React.Component {
  toggle = e => {
    this.props.toggle(e.target.textContent.toLowerCase());
  };
  render(){
  return (
    <div className="ui component grid fluid basic segment left aligned very padded">
      <div className="middle aligned column">
          <div className="ui huge header">
            Hi, I'm Jeremiah,
            <div className="content">
              <div className="sub header">
                Full Stack Web Developer based out of Atlanta. Skilled in making
                backend connections along with a well-polished user experience
                on the front end.
              </div>
            </div>
          </div>
          <button className="ui basic button" onClick={this.toggle}>ABOUT ME</button>
          <p></p>
          <button className="ui basic button" onClick={this.toggle}>PORTFOLIO</button>
          <p></p>
          <button className="ui basic button" onClick={this.toggle}>CONTACT</button>
        </div>
      </div>
  );
}
}
export default Profile;
