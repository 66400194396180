import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import drinksonImg from "../images/drinkson.gif";
import platescrapeImg from "../images/PlateScrape.gif";
import realtimeImg from "../images/RealTime.gif";
import giftasticImg from "../images/GifTastic.gif";
import boardManagerImg from "../images/BoardManagerScreen.png";

function Portfolio() {
  const mernButtons = (
    <>
      <button className="ui mini button">MONGO DB</button>
      <button className="ui mini button">EXPRESS</button>
      <button className="ui mini button">REACT</button>
      <button className="ui mini button">NODE</button>
    </>
  );

  const react = <i className="react icon"></i>;
  // const html = <i className="html5 icon"></i>;
  const js = <i className="js icon"></i>;
  return (
    <>
      <div id="timeline" className="ui two column centered grid">
        <div className="twelve wide column">
          <div className="ui huge header">
            <div className="content">Projects {"&"} Developments </div>
            <div className="sub header">
              Here are a list of projects I have worked on:
            </div>
          </div>
          <VerticalTimeline className="ui aligned left" layout="1-column">
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              date={mernButtons}
              iconStyle={{ background: "#fff", color: "rgb(23, 97, 13, 0.8)" }}
              icon={react}
              position="right"
            >
              <div className="ui grid">
                <a
                  className="project-thumb desktop"
                  href="https://sheltered-reef-36037.herokuapp.com/"
                >
                  <img
                    src={boardManagerImg}
                    alt="BoardManager Thumb"
                    className="ui fluid image"
                  />
                </a>
                <div className="project-content">
                  <div className="ui basic segment">
                    <h3 className="vertical-timeline-element-title">
                      <a href="https://sheltered-reef-36037.herokuapp.com/">
                        Board Manager
                      </a>
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      <em>In Development Stage</em>
                    </h4>
                    <a href="https://github.com/jtgraham5th/ToolBox-Tutors">
                      <button class="ui icon button basic small">
                        <i class="large secondary github icon icon"></i>
                      </button>
                    </a>
                    <p>
                      Board Manager is a tool used to keep track of the users in
                      a money board or gifiting board. This tool allows you to
                      create and manage new boards, and keep track of each users
                      standings on each board they are apart of and if they have
                      made a contributuion.
                    </p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={mernButtons}
              iconStyle={{ background: "#fff", color: "rgb(23, 97, 13, 0.8)" }}
              icon={react}
              position="right"
            >
              <div className="ui grid">
                <a
                  className="project-thumb"
                  href="https://serene-plateau-07976.herokuapp.com/"
                >
                  <img
                    src={platescrapeImg}
                    alt="PlateScrape Thumb"
                    className="ui fluid image"
                  />
                </a>
                <div className="project-content">
                  <div className="ui basic segment">
                    <h3 className="vertical-timeline-element-title">
                      <a href="https://serene-plateau-07976.herokuapp.com/">
                        PlateScrape
                      </a>
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      <em>In Development</em>
                    </h4>
                    <a href="https://github.com/jtgraham5th/PlateScrape">
                      <button class="ui icon button basic small">
                        <i class="large secondary github icon icon"></i>
                      </button>
                    </a>
                    <p>
                      PlateScrape takes an online recipe and retrieves all the
                      ingredients and creates a list of all the items and
                      amounts needed to create the recipes they have scraped.
                    </p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={mernButtons}
              iconStyle={{ background: "#fff", color: "rgb(23, 97, 13, 0.8)" }}
              icon={react}
              position="right"
            >
              <div className="ui grid">
                <a
                  className="project-thumb"
                  href="http://dry-brushlands-95291.herokuapp.com/"
                >
                  <img
                    src={drinksonImg}
                    alt="drinkson_thumb"
                    className="ui fluid image"
                  />
                </a>
                <div className="project-content">
                  <div className="ui basic segment">
                    <h3 className="vertical-timeline-element-title">
                      <a href="http://dry-brushlands-95291.herokuapp.com/">
                        Drinks on Us
                      </a>
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      GA Tech Bootcamp
                    </h4>
                    <a href="https://github.com/jtgraham5th/Drinks-On">
                      <button class="ui icon button basic small">
                        <i class="large secondary github icon icon"></i>
                      </button>
                    </a>
                    <p>
                      Drinkson is an mobile web app that allows users to checkin
                      to a bar and order drinks via the mobile app with the tap
                      of your finger. You can alsosedn drinks to other users
                      that have checked in to the same location.
                    </p>
                    <a href="http://drinkson.herokuapp.com/">
                      First Group Project Version
                    </a>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={
                <>
                  <button className="ui mini button">JAVASCRIPT</button>
                  <button className="ui mini button">HTML</button>
                </>
              }
              iconStyle={{ background: "#fff", color: "rgb(23, 97, 13, 0.8)" }}
              icon={js}
              position="right"
            >
              <div className="ui grid">
                <a
                  className="project-thumb desktop"
                  href="https://jtgraham5th.github.io/RealTime/"
                >
                  <img
                    src={realtimeImg}
                    alt="RealTime thumb"
                    className="ui fluid image"
                  />
                </a>
                <div className="project-content">
                  <div className="ui basic segment">
                    <h3 className="vertical-timeline-element-title">
                      <a href="https://jtgraham5th.github.io/RealTime/">
                        RealTime
                      </a>
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      GA Tech Bootcamp
                    </h4>
                    <a href="https://github.com/jtgraham5th/RealTime">
                      <button class="ui icon button basic small">
                        <i class="large secondary github icon icon"></i>
                      </button>
                    </a>
                    <p>
                      RealTime is an application that provides travel
                      information based on the users current location.
                    </p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={
                <>
                  <button className="ui mini button">JAVASCRIPT</button>
                  <button className="ui mini button">HTML</button>
                  <button className="ui mini button">JQUERY</button>
                </>
              }
              iconStyle={{ background: "#fff", color: "rgb(23, 97, 13, 0.8)" }}
              icon={js}
              position="right"
            >
              <div className="ui grid">
                <a
                  className="project-thumb desktop"
                  href="https://jtgraham5th.github.io/GifTastic/"
                >
                  <img
                    src={giftasticImg}
                    alt="GifTastic thumb"
                    className="ui fluid image"
                  />
                </a>
                <div className="project-content">
                  <div className="ui basic segment">
                    <h3 className="vertical-timeline-element-title">
                      <a href="https://jtgraham5th.github.io/GifTastic/">
                        GifTastic - Marvel vs DC
                      </a>
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      GA Tech Bootcamp
                    </h4>
                    <a href="https://github.com/jtgraham5th/GifTastic">
                      <button class="ui icon button basic small">
                        <i class="large secondary github icon icon"></i>
                      </button>
                    </a>
                    <p>
                      Simple web app that calls the GIPHY API and Superhero API
                      then uses Javascript and jQuery to change the HTML
                      content. Displays stats about Marvel vs DC Universe
                      characters.
                    </p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
    </>
  );
}
export default Portfolio;
