import React from "react";
import me01 from "../images/me01.jpg";

const MobileHeader = () => {
  return (
    <>
      <div
        className="ui center aligned top fixed attached row"
        id="mobile-header"
      >
        <div className="column">
          <img
            className="ui centered middle aligned tiny circular image"
            src={me01}
            alt="profile avatar"
          ></img>
          <a href="/">
            <h1 className="ui header">
              <div className="content">
                Jeremiah Graham
                <div className="sub header">Full Stack Web Developer</div>
              </div>
            </h1>
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
