import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar.js";
import Mobile from "./components/Mobile.js";
import Profile from "./components/Profile.js";
import Contact from "./components/Contact.js";
import Portfolio from "./components/Portfolio.js";
import MobileHeader from "./components/MobileHeader.js";
import Resume from "./components/Resume.js";
import AboutMe from "./components/AboutMe";

class App extends React.Component {
  state = { componentName: "profile" };

  toggle = (componentName) => {
    this.setState({ componentName: componentName.toLowerCase() });
  };
  renderComponent() {
    let componentName = this.state.componentName;
    switch (componentName) {
      case "portfolio":
        return <Portfolio toggle={this.toggle} />;
      case "profile":
        return <Profile toggle={this.toggle} />;
      case "contact":
        return <Contact toggle={this.toggle} />;
      case "about me":
        return <AboutMe toggle={this.toggle} />;
      case "resume":
        return <Resume toggle={this.toggle} />;
      default:
        return <Profile toggle={this.toggle} />;
    }
  }

  render() {
    return (
      <>
        <div id="desktop" className="ui equal width grid">
          <Sidebar toggle={this.toggle} />
          <div id="main-content" className="column center aligned">
            {this.renderComponent()}
          </div>
        </div>
        <div id="mobile" className="ui grid" style={{ position: "fixed" }}>
          {this.state.componentName === "profile" ? "" : <MobileHeader />}
          <Mobile toggle={this.toggle} />
          <div id="main-content" className="row">
            {this.renderComponent()}
          </div>
        </div>
      </>
    );
  }
}

export default App;
