import React from "react";
import ResumePDF from "../images/Jeremiah-Graham-Tech-Resume.pdf"

function Resume() {
  return (
    <div id="resume" className="ui two column centered grid">
      <div id="resume-holder" className="fourteen wide column">
        <div className="ui big header center aligned">
          <div className="content">Click <a href={ResumePDF}>here</a> to downlod a copy of my resume</div>
          <div className="sub header">
          </div>
        </div>
      </div>
    </div>
  );
}
export default Resume;
