import React from "react";
import Resume from "./Resume";

function Contact() {
  return (
    <div id="resume" className="ui two column centered grid">
      <div id="resume-holder" className="fourteen wide column">
        <div className="ui large header center aligned">
          <div className="content">Interested in working together?</div>
          <div className="sub header">
            If you are interested in working together on a project or if you
            have any questions, dont hestiate to reach out to me on linkedIn or
            contact me via email.
            <div className="ui basic segment">
            <table class="ui definition table">
              <tbody>
                <tr>
                  <td>
                    <i className="big linkedin icon"></i>
                    Linked In
                  </td>
                  <td>
                    <a href="https://www.linkedin.com/in/jeremiah-graham-5th/">
                      linkedin.com/in/jtgraham5th/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="big mail icon"></i>
                    Email
                  </td>
                  <td>
                    <a href="mailto:jtgraham5th@gmail.com">
                      jtgraham5th@gmail.com
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <Resume />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
