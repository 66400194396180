import React from "react";

function AboutMe() {
  return (
    <div id="resume" className="ui two column centered grid">
      <div id="resume-holder" className="fourteen wide column">
        <div className="ui huge header">
          <div className="content">About Me</div>
          <div className="sub header">
            <p>
              Web Developer with a background in Primary Education. Full Stack
              Web Development certification from Georgia Tech. Masters in
              Instructional Technology from Georgia Southern.
            </p>
            <p>
              Skilled in making back-end connnections with{" "}
              <strong>Express and ReactJS</strong> and developing a
              well-polished visually stimulating user expereince with{" "}
              <strong>HTML, CSS and Javascript.</strong>
            </p>
            <p class="mb-0">
              A Pragmatic-Optimist that is hardworking and a team player. Highly
              organized and motivated when it comes to solving problems and
              achieving goals. Dedicated to developing projects with the latest
              web technologies. Willing to go above and beyond for a project
              that recognizes their impact and wants to add value to their
              organization.
            </p>
          </div>
        </div>
        <div className="ui huge header basic segment">
          Skills
          <div className="sub header mb-5">
            <i className="huge html5 icon"></i>
            <i className="huge css3 icon"></i>
            <i className="huge-icon devicon-jquery-plain-wordmark"></i>
            <i className="huge js icon"></i>
            <i className="huge node icon"></i>
            <i className="huge-icon devicon-express-original-wordmark"></i>
            <i className="huge react icon"></i>
            <i className="huge-icon devicon-mysql-plain-wordmark"></i>
            <i className="huge-icon devicon-mongodb-plain-wordmark"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutMe;
